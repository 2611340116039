import React, { useState, useEffect } from 'react'
import Images from '../../../assets/Images'
import { numberWithCommas } from '../../../constants/utils'
import atoms from '../../atoms'

const { Coin, Currency } = Images
const { Input, Button, Checkbox } = atoms

const Index = ({ _handleCheckout, billingSummary, setBillingSummary, customerInfo,setCustomerInfo }: any) => {
    const totalDiscount = (billingSummary?.totalDiscount >= 0) && billingSummary?.totalDiscount

    useEffect(()=>{
        setBillingSummary((prevBillingSummary: any) => ({
            ...prevBillingSummary,
            loyalty_points: 0,
            redemption_request: false
        }));
    },[customerInfo?.mobile])

    const handleRedeem = (redemption_request: boolean) => {
        const checkedLoyaltyPoints = redemption_request ? (customerInfo?.loyalty_points || 0) : 0;
        const loyaltyToRedeem = Math.min(billingSummary.totalSales, checkedLoyaltyPoints);
        const redemptionRequest = loyaltyToRedeem >= 0 ? redemption_request : false;

        setBillingSummary((prevBillingSummary: any) => ({
            ...prevBillingSummary,
            loyalty_points: loyaltyToRedeem,
            redemption_request: redemptionRequest
        }));
        setCustomerInfo({...customerInfo,redemption_request: false })
    }


    return (
        <div className="checkout-card position-sticky mt-3 mt-md-0" style={{ position: '-webkit-sticky', top: '10.6rem', }}>
            <h2 className='mb-0 fs-md-18 fs-16 fw-600'>Billing Summary</h2>

            <div className="d-flex flex-wrap justify-content-between align-items-center mt-md-4 mt-3">
                <h2 className='mb-0 fs-md-16 fs-12 fw-500'>Subtotal</h2>
                <h2 className='mb-0 fs-md-16 fs-12 fw-500'>₹{numberWithCommas(parseFloat((billingSummary?.subTotal || 0)?.toFixed(2)))}</h2>
            </div>

            <div className="d-flex flex-wrap justify-content-between align-items-center mt-md-3 mt-2">
                <h2 className='mb-0 fs-md-16 fs-12 fw-500'>Discount</h2>
                <h2 className='mb-0 fs-md-16 fs-12 fw-500'>- ₹{numberWithCommas(parseFloat(Number(totalDiscount)?.toFixed(2)))}</h2>
            </div>

            <div className="d-flex flex-wrap justify-content-between align-items-center mt-md-3 mt-2">
                <h2 className='mb-0 fs-md-16 fs-12 fw-500'>Round Off</h2>
                <Input type="number" placeholder='' className='roundOff-inp text-end fs-12'
                    value={billingSummary?.roundOff}
                    onChange={(e: any) => e.target.value <= 10 && setBillingSummary({ ...billingSummary, roundOff: e.target.value, redemption_request: false })} />
            </div>

            <hr className="mt-md-3 mt-2" style={{ border: '1px solid #22222260', }} />
            <div className="d-flex flex-wrap justify-content-between align-items-center mt-md-3 mt-2">
                <h2 className='mb-0 fs-md-16 fs-12 fw-600'>Total Sales</h2>
                <h2 className='mb-0 fs-md-16 fs-12 fw-500'>₹{numberWithCommas(parseFloat((billingSummary?.totalSalesToShow || 0)?.toFixed(2)))}</h2>
            </div>
            <hr className="mt-md-3 mt-2" style={{ border: '1px solid #22222260', }} />
            <div className="d-flex flex-wrap justify-content-between mt-md-3 mt-2">
                <div className='my-auto'>
                    <h2 className={`mb-0 fs-md-16 fs-12  ${!(customerInfo?.loyalty_points && (customerInfo?.loyalty_points > billingSummary?.loyalty_points_limit)) ? "dark-gray fw-400" : "fw-600"}`}>Loyalty Points</h2>
                    <label className='d-flex gap-1' style={{
                        cursor: (customerInfo?.loyalty_points ? (customerInfo?.loyalty_points > billingSummary?.loyalty_points_limit) : false) ? "pointer" : "inherit"
                    }}>
                        <Checkbox className='cursor-pointer loyalty-checkbox'
                            onChange={(e: any) => handleRedeem(e.target.checked)}
                            id={'redeemId'}
                            checked={billingSummary?.redemption_request}
                            name={'redeemId'}
                            disabled={!customerInfo?.loyalty_points || (customerInfo.loyalty_points < billingSummary.loyalty_points_limit || billingSummary?.subTotal===0)} />
                        <p className={`my-auto p-0 text-unselected fs-14  ${(billingSummary?.redemption_request) ? "fw-600" : 'dark-gray fw-400'}`}>Redeem</p>
                    </label>
                </div>
                <div>
                    <p className={`'fs-md-16 fs-12 ' ${(billingSummary?.redemption_request) ? 'fw-600' : "dark-gray fw-400"}`}>{billingSummary?.redemption_request ? billingSummary?.loyalty_points : customerInfo?.loyalty_points}</p>
                </div>
            </div>
            <hr className="mt-md-3 mt-2" style={{ border: '1px solid #22222260', }} />
            <div className="d-flex flex-wrap justify-content-between align-items-center mt-md-3 mt-2">
                <h2 className='mb-0 fs-md-16 fs-12 fw-600'>Payable Amount</h2>
                <h2 className='mb-0 fs-14 fw-700'>₹{numberWithCommas(parseFloat(billingSummary?.totalSales?.toFixed(2))) || 0}</h2>
            </div>
            <hr className="mt-md-3 mt-2" style={{ border: '1px dashed #22222260', }} />

            <Button className='mb-0 fs-18 fw-500 text-white border-1 w-100 mt-2 proceed-btn'
                onClick={() => _handleCheckout()}
            >Proceed To Checkout</Button>

        </div>
    )
}

export default Index