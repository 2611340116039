import ProductList from './ProductList'
import ProductDetails from './ProductDetails'
import SalesCart from './SalesCart'
import PendingOrder from './PendingOrder'
import AccountDetail from './AccountDetail'
import Audit from './Audit'
import Warranty from './Warranty'
import WarrantyDetail from './WarrantyDetail'
import Claimed from './Claimed'
import ClaimedHistory from './ClaimedHistory'
import IssueNewProduct from './IssueNewProduct'
import SalesReturn from './SalesReturn'
import SalesReturnHistory from './SalesReturnHistory'
import StoreRequested from './StoreRequested'
import StoreRequestedHistory from './StoreRequestedHistory'
import PendingReceipt from './OrderHistory/Pending'
import InventoryAudit from './InventoryAudit'
import Delivered from './OrderHistory/Delivered'
import CancelledOrder from './OrderHistory/CancelledOrder'
import TransferRequest from './TransferRequest'
import Prescription from './Prescription'
import Dashboard from './Dashboard'
import PaymentVoucher from './PaymentVoucher'
import PaymentVoucherHistory from './PaymentVoucherHistory'
import EPendingOrder from "./OnlineOrder/EPendingOrder"
import EInProcess from "./OnlineOrder/EInProcess"
import ESalesHistory from "./OnlineOrder/ESalesHistory"
import ECancelledOrder from "./OnlineOrder/ECancelledOrder"
import EAccountDetail from "./OnlineOrder/EAccountDetail"
import InternalTransfer from "./InternalTransfer"
import ESalesCart from "./OnlineOrder/ESalesCart"


const organisms = {
    ProductList,
    ProductDetails,
    SalesCart,
    PendingOrder,
    AccountDetail,
    Audit,
    Warranty,
    WarrantyDetail,
    Claimed,
    ClaimedHistory,
    IssueNewProduct,
    SalesReturn,
    SalesReturnHistory,
    StoreRequested,
    StoreRequestedHistory,
    PendingReceipt,
    InventoryAudit,
    Delivered,
    CancelledOrder,
    TransferRequest,
    Prescription,
    Dashboard,
    PaymentVoucher,
    PaymentVoucherHistory,
    ESalesCart,
    EPendingOrder,
    EInProcess,
    ESalesHistory,
    ECancelledOrder,
    EAccountDetail,
    InternalTransfer
}
export default organisms