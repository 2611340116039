import React, { useState, useEffect } from 'react'
import Images from '../../../assets/Images'
import atoms from '../../atoms'
import { numberWithCommas } from '../../../constants/utils'
import constant from '../../../constants/constant'
import ConfirmationModal from '../ConfirmationModal'

const { Coin, Currency } = Images
const { Input, Button, Checkbox } = atoms

const Index = ({ _handleCheckout, billingSummary, setBillingSummary, orderDetails, cancelCart, isApproved,handleApproveOrder,isCancelledOrder }: any) => {
    const [handleCancelModal, setHandleCancelModal] = useState<boolean>(false)
    const [handleApprovedModal, setHandleApprovedModal] = useState<boolean>(false)
    const totalDiscount = (billingSummary?.totalDiscount >= 0) && billingSummary?.totalDiscount

    const handleRedeem = (redemption_request: boolean) => {
        const checkedLoyaltyPoints = redemption_request ? (orderDetails?.customer?.loyalty_points || 0) : 0;
        const loyaltyToRedeem = Math.min((billingSummary?.totalSales - billingSummary?.total_paid_amount), checkedLoyaltyPoints);
        const redemptionRequest = (((billingSummary?.totalSalesToShow - billingSummary?.total_paid_amount) > 0) && loyaltyToRedeem >= 0) ? redemption_request : false;

        setBillingSummary((prevBillingSummary: any) => ({
            ...prevBillingSummary,
            loyalty_points: loyaltyToRedeem,
            redemption_request: redemptionRequest
        }));
    }

    return (
        <div className="checkout-card position-sticky mt-4 mt-md-0" style={{ position: '-webkit-sticky', top: '7rem', }}>
            <h2 className='mb-0 fs-md-18 fs-16 fw-600'>Billing Summary</h2>

            <div className="d-flex flex-wrap justify-content-between align-items-center mt-4">
                <h2 className='mb-0 fs-md-16 fs-12 fw-500'>Subtotal</h2>
                <h2 className='mb-0 fs-md-16 fs-12 fw-500'>₹{numberWithCommas(parseFloat((Number(billingSummary?.subTotal))?.toFixed(2))) || 0}</h2>
            </div>

            <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                <h2 className='mb-0 fs-md-16 fs-12 fw-500'>Discount</h2>
                <h2 className='mb-0 fs-md-16 fs-12 fw-500'>- ₹{numberWithCommas(parseFloat(Number(totalDiscount)?.toFixed(2)))}</h2>
            </div>

          {!isApproved &&  <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                <h2 className='mb-0 fs-md-16 fs-12 fw-500'>Round Off</h2>
                <Input type="number" placeholder='' className='roundOff-inp text-end'
                    value={billingSummary?.roundOff}
                    disabled={(orderDetails?.order_status == constant.ORDER_STATUS.DELIVERED)}
                    onChange={(e: any) => e.target.value <= 10 && setBillingSummary({ ...billingSummary, roundOff: e.target.value, redemption_request: false })} />
            </div>}
            <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                <h2 className='mb-0 fs-md-16 fs-12 fw-500'>Coupon Discount</h2>
                <h2 className='mb-0 fs-md-16 fs-12 fw-500'>-₹{numberWithCommas(parseFloat((Number(billingSummary?.coupon_discount_amount) || 0)?.toFixed(2))) || 0}</h2>
            </div>

            <hr className="mt-3" style={{ border: '1px solid #22222260', }} />
            <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                <h2 className='mb-0 fs-md-16 fs-12 fw-600'>Total Sales</h2>
                <h2 className='mb-0 fs-md-16 fs-12 fw-500'>₹{numberWithCommas(parseFloat(((Number(billingSummary?.totalSalesToShow) - Number(billingSummary?.coupon_discount_amount)) || 0)?.toFixed(2))) || 0}</h2>
            </div>
            <hr className="mt-3" style={{ border: '1px solid #22222260', }} />
         {!isApproved && <div className="d-flex justify-content-between">
                <div>
                    <h2 className={`mb-0 fs-md-16 fs-12  ${!(orderDetails?.customer?.loyalty_points && (orderDetails?.customer?.loyalty_points > 100)) ? "dark-gray fw-400" : "fw-600"}`}>Loyalty Points</h2>
                    <label className='d-flex gap-2 mt-2' style={{
                        cursor: (orderDetails?.customer?.loyalty_points ? (orderDetails?.customer?.loyalty_points > 100) : false) ? "pointer" : "inherit"
                    }}>
                        <Checkbox className='cursor-pointer loyalty-checkbox'
                            onChange={(e: any) => handleRedeem(e.target.checked)}
                            id={'redeemId'}
                            checked={billingSummary?.redemption_request}
                            name={'redeemId'}
                            disabled={!orderDetails?.customer?.loyalty_points || (orderDetails?.customer?.loyalty_points < billingSummary?.loyalty_point_limit) || (billingSummary?.order_status === constant.ORDER_ITEM_STATUS.DELIVERED)} />
                        <p className={`mb-0 text-unselected fs-14  ${(billingSummary?.redemption_request) ? "fw-600" : 'dark-gray fw-400'}`}>Redeem</p>
                    </label>
                </div>
                <div>
                    <p className={`'fs-md-16 fs-12 ' ${(billingSummary?.redemption_request) ? 'fw-600' : "dark-gray fw-400"}`}>{(billingSummary?.order_status === constant.ORDER_ITEM_STATUS.DELIVERED) ? billingSummary?.redeemed_points : (billingSummary?.redemption_request ? billingSummary?.loyalty_points : orderDetails?.customer?.loyalty_points)}</p>
                </div>
            </div>}


            <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                <h2 className='mb-0 fs-md-16 fs-12 fw-500'>Advance Amount</h2>
                <h2 className='mb-0 fs-md-16 fs-12 fw-500'>₹{numberWithCommas(parseFloat(billingSummary?.total_paid_amount)) || 0}</h2>
            </div>

            <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                <h2 className='mb-0 fs-md-16 fs-12 fw-500'>Due Amount</h2>
                <h2 className='mb-0 fs-md-16 fs-12 fw-500'>₹{numberWithCommas(parseFloat((Number(billingSummary?.totalSales) - Number(billingSummary?.total_paid_amount )- Number(billingSummary?.coupon_discount_amount))?.toFixed(2))) || 0}</h2>
                {/* <Input type="text" placeholder='' className='roundOff-inp text-end'
                    value={billingSummary?.due_amount}
                    onChange={(e: any) => setBillingSummary({ ...billingSummary, due_amount: e.target.value })} /> */}
            </div>

            <hr className="mt-3" style={{ border: '1px dashed #22222260', }} />
            <div className="d-flex flex-wrap justify-content-between align-items-center my-3">
                <h2 className='mb-0 fs-md-16 fs-12 fw-600'>Payable Amount</h2>
                <h2 className='mb-0 fs-md-14 fs-12 fw-700'>₹{numberWithCommas(parseFloat((Number(billingSummary?.totalSales) - Number(billingSummary?.total_paid_amount) - Number(billingSummary?.coupon_discount_amount))?.toFixed(2))) || 0}</h2>
            </div>

            {isApproved && !isCancelledOrder &&
                <Button className='mb-0 fs-md-18 fs-16 fw-500 text-white border-1 w-100 mt-3 proceed-btn'
                    onClick={() => setHandleApprovedModal(!handleApprovedModal)}
                >Approve Order</Button>
            }
            {!isCancelledOrder && !isApproved && (orderDetails?.order_status !== constant.ORDER_STATUS.DELIVERED) &&
                <Button className='mb-0 fs-md-18 fs-16 fw-500 text-white border-1 w-100 mt-3 proceed-btn'
                    onClick={() => _handleCheckout()}
                >Proceed To Checkout</Button>
            }
            {!isCancelledOrder && (orderDetails?.order_status !== constant.ORDER_STATUS.DELIVERED) &&
                <Button className='mb-0 fs-md-18 fs-16 fw-500 text-white border-1 w-100 mt-3 cancel-btn'
                    onClick={() => setHandleCancelModal(!handleCancelModal)}
                >Cancel Order</Button>
            }

            {
                handleCancelModal && <ConfirmationModal
                    show={handleCancelModal}
                    handleConfirmItem={() => cancelCart()}
                    onHide={() => setHandleCancelModal(!handleCancelModal)}
                    message={'cancel order'} />
            }
            {
                handleApprovedModal && <ConfirmationModal
                    show={handleApprovedModal}
                    handleConfirmItem={() => handleApproveOrder()}
                    onHide={() => setHandleApprovedModal(!handleApprovedModal)}
                    message={'approved this order'} />
            }
        </div>
    )
}

export default Index